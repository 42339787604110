
import { Vue, Component } from 'vue-property-decorator'
import { TradingOptionsEditor } from '@/components/collections/business'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'

@Component({
  components: {
    TradingOptionsEditor
  }
})
export default class TradingOptions extends Vue {
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch
}

